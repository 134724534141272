import React, { useState, useEffect } from "react";
import Chat from "../../components/Chat/chat";
import { useParams, useNavigate } from "react-router-dom";

import { BotIcon, EditIcon } from "../../icons";
import unnamed from "../../assets/img/unnamed 2.jpg";
import MessageBox from "../../components/MessageBox/message-box";
import PublishModal from "../../Modals/publish-modal";
import { Button } from "@windmill/react-ui";
import { getAgentById, updateAgentName } from "../../services/agent-service";
import { useAuth } from "../../context/authContext";

const CreateAgent = () => {
  const windowWidth = window.innerWidth < 600;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [createClick, setCreateClick] = useState(true);
  const [previewClick, setPreviewClick] = useState(false);
  const [name, setName] = useState("");
  const { tenantId } = useAuth();
  const { id } = useParams();
  console.log(id);
  const updateAgent = async () => {
    let payload = {
      tenantId: tenantId,
      name: name,
      agentId: id,
    };
    const res = await updateAgentName(payload);
    if (res && res.status === 200) {
      // navigate("/agent/" + res.data.data.agentId);
    }
  };

  const getAgentDetails = async () => {
    let payload = {
      tenantId: tenantId,
      agentId: id,
    };
    const res = await getAgentById(payload);
    if (res && res.status === 200) {
      setName(res.data.name);
    }
  };
  useEffect(() => {
    if (id) {
      getAgentDetails();
    }
  }, [id]);
  const createChatData = {
    title: "Create",
    description: "Karr will help you to build and customize your agent",
    chatData: [
      {
        name: "Karr",
        icon: <BotIcon className="h-8 w-8 rounded-full p-1 bg-bright-red" />,
        chat: "Hi Parth, My name is Karr, I’m here to help you build your new AI sales agent. I will ask you certain questions to get to know you more. Feel free to ask me to clarify or give more information anytime!",
      },
      {
        name: "Karr",
        icon: <BotIcon className="h-8 w-8 rounded-full p-1 bg-bright-red" />,
        chat: "Can you describe your business in a few sentences?",
      },
      {
        name: "You",
        icon: <img src={unnamed} className="h-8 w-20" />,

        chat: "Hi Karr, we build AI sales agents for our customers. At the speed of thought, our AI Sales Agents decode your business landscape, customizing interactions with sharp emotional intelligence.",
      },
    ],
  };
  const previewData = {
    title: "Preview",
    description: "Test your agent in real-time as you customize it",
    chatData: [
      {
        name: "My Agent",
        icon: <BotIcon className="h-8 w-8 rounded-full p-1 bg-bright-red" />,
        chat: "Hi, welcome to Karmaflow. How can I help you today?",
      },
      {
        name: "Customer",
        icon: <img src={unnamed} className="h-8 w-8" />,
        chat: "What services do you provide?",
      },
    ],
  };

  return (
    <React.Fragment>
      <div className="h-24 border-b-2 border-dark-gray flex justify-between items-center px-5">
        <div>
          <div className="flex">
            <input
              className="text-xl flex font-semibold mb-3 bg-gray rounded-lg outline-none px-3 py-1"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <div
              className="w-8 h-8 bg-regal-blue mt-1 text-white ml-3 cursor-pointer rounded-full flex justify-center items-center"
              onClick={() => updateAgent()}
            >
              <EditIcon className="w-5 h-5" />
            </div>
          </div>
          <span className="text-bright-Gray border-dotted border-2 border-black py-1 pr-3 px-2 text-sm ">
            • Draft
          </span>
        </div>
        <button
          className="h-12 w-40 bg-green text-white rounded-lg relative focus:outline-none"
          onClick={() => setIsModalOpen(true)}
        >
          Publish
        </button>
      </div>
      {windowWidth && (
        <>
          <div className="flex my-2">
            <Button
              className="mx-4 bg-regal-blue active:bg-regal-blue focus:bg-regal-blue hover:bg-regal-blue"
              onClick={() => {
                setCreateClick(true);
                setPreviewClick(false);
              }}
            >
              Create
            </Button>
            <Button
              className="bg-regal-blue active:bg-regal-blue focus:bg-regal-blue hover:bg-regal-blue"
              onClick={() => {
                setPreviewClick(true);
                setCreateClick(false);
              }}
            >
              Preview
            </Button>
          </div>
        </>
      )}
      {windowWidth && createClick && (
        <>
          <Chat chatData={createChatData} windowWidth={windowWidth} />
          <MessageBox />
        </>
      )}
      {previewClick && (
        <>
          <Chat chatData={previewData} windowWidth={windowWidth} />
          <MessageBox />
        </>
      )}
      {!windowWidth && (
        <div className="grid grid-cols-2" style={{ width: "calc(100% - 8px)" }}>
          <div
            className="border-r-2 border-[#B2B2B2] relative px-2"
            style={{ height: "calc(100vh - 10rem)" }}
          >
            <Chat chatData={createChatData} />
            <MessageBox />
          </div>
          <div
            className="relative px-2"
            style={{ height: "calc(100vh - 10rem)" }}
          >
            <Chat chatData={previewData} />
            <MessageBox />
          </div>
        </div>
      )}

      {
        <PublishModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      }
    </React.Fragment>
  );
};

export default CreateAgent;
