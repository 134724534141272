import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/authContext";
import { apiInstance } from "../services/axios-service";
const PublicRoute = (props) => {
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/home");
    }
  }, []);

  return <React.Fragment>{!isLoggedIn ? props.children : null}</React.Fragment>;
};
export default PublicRoute;
