import React from "react";

function ThemedSuspense() {
  return (
    <div className="w-full h-screen p-6 text-lg font-medium text-gray-600 ">
      Loading...
    </div>
  );
}

export default ThemedSuspense;
