import React, { lazy } from "react";
import { Toaster } from "react-hot-toast";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
// import PrivateRoute from "./routes/PrivateRoute";
import Layout from "./containers/Layout";
import GetStarted from "./pages/Get-Started/get-started";
import CreateAgent from "./pages/Create-Agent/create-agent";
import SetupLayout from "./containers/setup-layout";
import VerifyEmail from "./pages/VerifyEmail/verify-email";
import VerifyNumber from "./pages/VerifyNumber/verify-number";
import BillingSetup from "./pages/Billing-Setup/billing-setup";
import InviteMember from "./pages/Invite-Member/invite-member";
import OrganizationSetup from "./pages/Organization-Setup/organization-setup";
import { useAuth } from "./context/authContext";
import PublicRoute from "./routes/PublicRoute";
import Conversations from "./pages/Conversations/conversations";
import ConversationDetails from "./pages/Conversation-Details/conversation-details";
import Agents from "./pages/Agents/agents";
import Congratulations from "./pages/Congratulations/congratulations";
const Home = lazy(() => import("./pages/Home/home"));
const Login = lazy(() => import("./pages/Login/login"));
const AuthOptions = lazy(() => import("./pages/AuthOptions/auth-options"));
const SignupOptions = lazy(() =>
  import("./pages/SignupOptions/signup-options")
);
const Signup = lazy(() => import("./pages/Signup/signup"));
const AuthDetails = lazy(() => import("./pages/AuthDetails/auth-details"));
const VerifyAuth = lazy(() => import("./pages/VerifyAuth/verify-auth"));
const ResetPassword = lazy(() =>
  import("./pages/Reset-Password/reset-password")
);

function App() {
  const { isLoggedIn } = useAuth();

  return (
    <>
      <BrowserRouter>
        {/* <AccessibleNavigationAnnouncer /> */}
        <Routes>
          {/* Public routes */}
          <Route
            path="/login"
            element={
              <PublicRoute>
                <AuthOptions />
              </PublicRoute>
            }
          />
          <Route
            path="/signup"
            element={
              <PublicRoute>
                <SignupOptions />
              </PublicRoute>
            }
          />
          <Route
            path="/onboarding/introduction/:sub"
            element={
              <PublicRoute>
                <AuthDetails />
              </PublicRoute>
            }
          />
          <Route
            path="/login/email"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path="/signup/email"
            element={
              <PublicRoute>
                <Signup />{" "}
              </PublicRoute>
            }
          />

          <Route
            path="/"
            element={isLoggedIn ? <Layout /> : <Navigate to="/login" replace />}
          >
            <Route index element={<Home />} />
            <Route path="home" element={<Home />} />
            <Route path="congratulations" element={<Congratulations />} />
            <Route path="get-started" element={<GetStarted />} />
            <Route path="conversations" element={<Conversations />} />
            <Route path="agents" element={<Agents />} />
            <Route
              path="conversation-details/:id"
              element={<ConversationDetails />}
            />

            <Route path="agent/:id" element={<CreateAgent />} />
            <Route path="organization" element={<OrganizationSetup />} />
            {/* <Route path="setup" element={<SetupLayout />}> */}
            <Route index element={<VerifyEmail />} />
            <Route path="verify-email" element={<VerifyEmail />} />
            <Route path="verify-number" element={<VerifyNumber />} />
            <Route path="billing" element={<BillingSetup />} />
            <Route path="team-member" element={<InviteMember />} />
            {/* </Route> */}
          </Route>
        </Routes>
        <Toaster />
      </BrowserRouter>
    </>
  );
}

export default App;
