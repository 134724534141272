import React from "react";
import { ArrowIcon, StreamlineIcon } from "../../icons";
import Streamline from "../../icons/Streamline.svg";
import Arrow from "../../icons/arrow.svg";

const MessageBox = () => {
  return (
    <>
      <div className="absolute w-full bottom-0 mb-4">
        <div className="h-12 mx-8 px-5 flex justify-between items-center bg-gray rounded-full ">
          <img src={Streamline} />
          <input
            type="text"
            className="w-full h-full bg-gray outline-none ml-3"
          />
          <div>
            <img src={Arrow} />
          </div>
        </div>
      </div>
    </>
  );
};

export default MessageBox;
