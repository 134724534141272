import React from "react";
import { Modal, Card } from "@windmill/react-ui";
import copy from "../assets/img/copy.png";
import { useNavigate } from "react-router-dom";

const PublishModal = ({ isModalOpen, setIsModalOpen }) => {
  const navigate = useNavigate();
  return (
    <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
      <div className="p-4 pt-0">
        <h1 className="flex text-2xl font-semibold justify-center">
          Embed Code
        </h1>
        <p className="text-sm my-3">
          Paste this code just above your closing body tag.
        </p>

        <div className="bg-dark-gray flex justify-center p-3">
          <h6 className="text-xs text-disabled">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean sit
            amet pharetra neque. Nullam massa sapien, ultricies at pretium
            vitae, condimentum id diam. Aliquam quis commodo tortor. semper
            condimentum risus. Sed et purus eget neque porta luctus quis ac
            justo. Sed fringilla risus at lorem iaculis, ut egestas dui
            condimentum. Sed aliquam metus sem, eget egestas dolor congue ut.
            Fusce egestas velit mauris, sagittis tempor nulla porta vitae. Donec
            ornare, leo et convallis pharetra, diam nulla viverra turpis, eget
            malesuada sem orci sed justo. Morbi lacinia pulvinar volutpat. Etiam
            luctus rutrum libero, at laoreet elit pretium vel.
          </h6>
        </div>
        <div className="flex mt-5 justify-between px-4">
          <button className="bg-egray py-2 px-4 rounded-lg focus:outline-none text-white flex">
            Copy
            <img src={copy} className="ml-2" />
          </button>
          <button
            className="bg-green py-2 px-4 rounded-lg focus:outline-none text-white"
            onClick={() => navigate("/setup/*")}
          >
            Complete Account Setup
          </button>
        </div>
       </div>
    </Modal>
  );
};

export default PublishModal;
