import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "../context/authContext";
import Header from "../components/Header/header";
import Sidebar from "../components/Sidebar/sidebar";
import { apiInstance } from "../services/axios-service";

function Layout() {
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();
  const [isExpanded, setExpanded] = useState(true);
  const [isExpandedMob, setExpandedMob] = useState(false);
  const checkUserToken = () => {
    if (!isLoggedIn) {
      return navigate("/login");
    } else {
      apiInstance.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("idToken");
    }
  };

  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn]);

  return isLoggedIn ? (
    <div>
      <div className="layout__wrapper">
        <Header
          setExpanded={setExpanded}
          isExpanded={isExpanded}
          setExpandedMob={setExpandedMob}
          isExpandedMob={isExpandedMob}
        />
        <Sidebar
          isExpanded={isExpanded}
          isExpandedMob={isExpandedMob}
          setExpandedMob={setExpandedMob}
        />
        <div
          className={`outlet_wrapper pt-[95px] pb-[80px] ${
            isExpanded ? "ml-20" : "ml-56"
          } relative overflow-auto 
              ${isExpanded ? "ml-0" : ""}
           `}
          style={{ height: "calc(100vh - 4rem)" }}
        >
          <Outlet />
        </div>
      </div>
    </div>
  ) : null;
}

export default Layout;
