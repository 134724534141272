// import firebase from "firebase/app";
import "firebase/compat/auth";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  sendPasswordResetEmail,
  sendEmailVerification,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
  onAuthStateChanged,
  RecaptchaVerifier,
} from "firebase/auth";
import React, { createContext, useContext, useEffect, useState } from "react";
import { apiInstance } from "../services/axios-service";
import toast from "react-hot-toast";
import apiHeader from "../services/api-headers";
import { getSetupInfo, setEmailVerified } from "../services/setup-service";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: "chat-agent-dev-415420.appspot.com",
  messagingSenderId: "139978389761",
  appId: process.env.REACT_APP_APP_ID,
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth();
// const auth = firebaseApp.auth();
const googleProvider = new GoogleAuthProvider();
const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(
    localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null
  );
  const [tenantId, setTenantId] = useState(
    localStorage.getItem("tenantId") ? localStorage.getItem("tenantId") : null
  );
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("isLoggedIn")
      ? localStorage.getItem("isLoggedIn")
      : false
  );
  const [token, setToken] = useState(
    localStorage.getItem("idToken") ? localStorage.getItem("idToken") : null
  );

  const getOrganizationDetail = async () => {
    if (tenantId) {
      let payload = {
        tenantId: tenantId,
      };
      const res = await getSetupInfo(payload);
      if (res && res.status === 200) {
        if (res?.data) {
          if (!res?.data?.email) {
            const result = await setEmailVerified({});
          }
        }
      }
    }
  };

  const loginWithGoogle = async () => {
    const result = await signInWithPopup(auth, googleProvider);
    const auser = result.user;
    const authorizeToken = auser && (await auser.getIdToken());
    setToken(authorizeToken);
    setUser(auser);
    localStorage.setItem("idToken", authorizeToken);
    apiInstance.defaults.headers.common["Authorization"] =
      "Bearer " + authorizeToken;
    // The signed-in user info.
    localStorage.setItem("user", JSON.stringify(auser));
    return auser;
  };

  const logOut = async () => {
    const res = await signOut(auth);
    return res;
  };

  const loginWithCredentials = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      // Signed in
      const user = userCredential.user;
      const token = user && (await user.getIdToken());
      setToken(token);
      localStorage.setItem("idToken", token);
      localStorage.setItem("user", JSON.stringify(user));
      setUser(user);
      return user;
      // ...
    } catch (error) {
      console.error("Authentication error:", error.code);
      toast.error("Invalid Credentials!");
      return error;
    }
  };

  useEffect(() => {
    console.log("??????");
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && user.emailVerified) {
        setUser(user);
        getOrganizationDetail();
      }
    });

    // Unsubscribe from the authentication state changes when the component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    localStorage.removeItem("theme");
    if (token) {
      apiInstance.defaults.headers.common["Authorization"] = "Bearer " + token;
    }
  }, []);

  useEffect(() => {
    const isLogin = localStorage.getItem("isLoggedIn");
    setIsLoggedIn(isLogin);
  }, [isLoggedIn]);

  const registerWithCredentials = async (email, password) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      // Signed up
      const user = userCredential.user;
      const token = user && (await user.getIdToken());
      localStorage.setItem("idToken", token);
      localStorage.setItem("user", JSON.stringify(user));
      setToken(token);
      setUser(user);
      return user;
    } catch (error) {
      console.error("Authentication error:", error);
      toast.error("Something went wrong!");
      return error;
    }
  };

  const saveUserDetails = async (data) => {
    const res = await apiInstance.post("user/onboard", data, {
      headers: apiHeader(),
    });
    return res;
  };

  const checkIsUserExist = async () => {
    console.log(localStorage.getItem("idToken"));
    if (localStorage.getItem("idToken")) {
      const res = await apiInstance.get("user/get-user-info", {
        headers: apiHeader(),
      });
      return res;
    }
  };

  const resetPassword = async (email) => {
    const res = await sendPasswordResetEmail(auth, email);
    console.log(res);
    return res;
  };
  const sendVerificationEmail = async () => {
    const auser = auth.currentUser;

    if (auser) {
      sendEmailVerification(auser)
        .then((res) => {
          // Email verification sent
          console.log("Email verification sent", res);
        })
        .catch((error) => {
          // Error occurred. Handle error
          console.error(error.message);
        });
    } else {
      console.error("No user is signed in.");
    }
  };
  const sendVerificationCode = async (phoneNumber) => {
    // const appVerifier = auth.RecaptchaVerifier("recaptcha-container");
    // console.log(appVerifier);
    const recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          console.log(response);
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
      }
    );

    const res = await signInWithPhoneNumber(
      auth,
      phoneNumber,
      recaptchaVerifier
    );

    // Verification code sent successfully
    // Save confirmationResult for later use
    console.log(res);
    return res;
  };
  const verifyVerificationCode = async (confirmationResult, code) => {
    const res = await confirmationResult.confirm(code);
    return res;
  };
  return (
    <AuthContext.Provider
      value={{
        user,
        loginWithCredentials,
        loginWithGoogle,
        registerWithCredentials,
        resetPassword,
        isLoggedIn,
        sendVerificationCode,
        sendVerificationEmail,
        verifyVerificationCode,
        setIsLoggedIn,
        logOut,
        tenantId,
        setTenantId,
        token,
        setToken,
        setUser,
        checkIsUserExist,
        saveUserDetails,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
