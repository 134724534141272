import axios from "axios";
import toast from "react-hot-toast";

export const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {},
});

apiInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log(error);
    if (error.response.data.error === "Unauthorized") {
      localStorage.clear();
      window.location.href = "/login";
    }
    toast.error(error.response.data.error);
    return error;
  }
);
