import apiHeader from "./api-headers";
import { apiInstance } from "./axios-service";

export const setEmailVerified = async (payload) => {
  return apiInstance.post("user/set-email-verified", payload, {
    headers: apiHeader(),
  });
};

export const setMobileVerified = async (payload) => {
  return apiInstance.post("user/set-phone-verified", payload, {
    headers: apiHeader(),
  });
};

export const getSetupInfo = async (payload) => {
  return apiInstance.post("user/get-setup-info", payload, {
    headers: apiHeader(),
  });
};

export const setBillingVerified = async (payload) => {
  return apiInstance.post("tenant/set-billing-setup-completed", payload, {
    headers: apiHeader(),
  });
};

export const getTenantInfo = async (payload) => {
  return apiInstance.post("tenant/get-tenant-info", payload, {
    headers: apiHeader(),
  });
};

export const getRoles = async () => {
  return apiInstance.get("tenant/list-roles", { headers: apiHeader() });
};

export const getInvitedUser = async (payload) => {
  return apiInstance.post("tenant/list-invitations", payload, {
    headers: apiHeader(),
  });
};

export const inviteUser = async (payload) => {
  return apiInstance.post("user/invite-user", payload, {
    headers: apiHeader(),
  });
};

export const saveInvitedUser = async (payload) => {
  return apiInstance.post("user/create-tenant-user", payload, {
    headers: apiHeader(),
  });
};

export const saveTenantForInvited = async (payload) => {
  return apiInstance.post("user/join-tenant", payload, {
    headers: apiHeader(),
  });
};
