import React from "react";

const Chat = ({ chatData, windowWidth }) => {
  return (
    <React.Fragment>
      <div className="text-center mt-5 pt-2 ">
        <h3 className="text-3xl font-semibold">{chatData?.title}</h3>
        <p className="text-sm px-3">{chatData?.description}</p>
      </div>
      <div className={`${windowWidth ? "px-4" : ""}`}>
        {chatData?.chatData?.map((x, i) => {
          return (
            <div
              className="flex pt-8 lg:px-12 md:px-8 lg:mx-3 md:mx-0 "
              key={i}
            >
              <div>{x?.icon}</div>
              <div className="ml-5">
                <h2 className="text-xl font-semibold">{x?.name}</h2>
                <p className="text-xs mt-1">{x?.chat} </p>
              </div>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};
export default Chat;
