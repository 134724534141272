import React, { useEffect, useState } from "react";
import { Dropdown, DropdownItem, Button, Input } from "@windmill/react-ui";
import Arrow from "../../icons/arrow.svg";
import { DropdownIcon } from "../../icons";
import {
  getInvitedUser,
  getRoles,
  inviteUser,
} from "../../services/setup-service";
import toast from "react-hot-toast";
import { useAuth } from "../../context/authContext";
const InviteMember = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [roles, setRoles] = useState([]);
  const [email, setEmail] = useState();
  const [user, setUser] = useState();
  const { tenantId } = useAuth();
  const [selectedRole, setSelectedRole] = useState("Select Role");
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const fetchRoles = async () => {
    const res = await getRoles();
    if (res && res.status === 200) {
      console.log(res);
      setRoles(res.data.roles);
    }
  };

  const fetchInvitedUser = async () => {
    const res = await getInvitedUser({
      tenantId: tenantId,
    });
    if (res && res.status === 200) {
      console.log(res);
      setUser(res.data.invitations);
    }
  };

  useEffect(() => {
    fetchRoles();
    fetchInvitedUser();
  }, []);

  const handleClick = async (e) => {
    e.preventDefault();
    if (selectedRole != "Select Role") {
      let payload = {
        tenantId: tenantId,
        email: email,
        role: selectedRole,
      };
      const res = await inviteUser(payload);
      if (res && res.status === 200) {
        toast.success("User Invited Successfully!");
        fetchInvitedUser();
      }
    } else {
      toast.error("Please select role!");
    }
  };

  return (
    <div className="ml-64 px-12">
      <h2 className="my-10 text-3xl font-semibold text-dark-black">
        Invite Team Members (Optional)
      </h2>
      <p className="text-bright-Gray text-lg">
        You can add your team members to collaborate with you. Enter their
        <br /> email and select a role.
      </p>
      <div className="lg:flex lg:flex-row mt-8 md:flex-col items-center">
        <div className="relative">
          <Button
            className="bg-bright-orange hover:bg-bright-orange focus:bg-bright-orange focus:shadow-outline-bright-orange active:shadow-none active:bg-bright-orange"
            onClick={toggleDropdown}
            aria-label="Notifications"
            aria-haspopup="true"
          >
            {selectedRole}
            <DropdownIcon className="h-6 w-6 ml-4" />
          </Button>
          <Dropdown isOpen={isOpen} onClose={() => setIsOpen(false)}>
            {roles?.map((r, i) => {
              return (
                <DropdownItem
                  onClick={() => setSelectedRole(r)}
                  className="justify-between"
                  key={i}
                >
                  <span>{r}</span>
                </DropdownItem>
              );
            })}
          </Dropdown>
        </div>
        <form onSubmit={(e) => handleClick(e)}>
          <div className="lg:ml-8  lg:mt-0  md:pl-0 md:ml-0 md:mt-4 ">
            <div
              className="flex justify-between rounded-full w-full bg-white px-5 py-2"
              style={{ boxShadow: "#E5E6FF 3px 0px 13px 0px" }}
            >
              <Input
                type="text"
                placeholder="Enter Email"
                className="focus:outline-none outline-none border-none w-full"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button layout="link" type="submit">
                {" "}
                <img src={Arrow} />
              </Button>
            </div>
          </div>
        </form>
      </div>
      <div className="p-8 bg-light-gray mt-12 lg:w-4/5 md:w-full">
        {user?.map((u, i) => {
          return (
            <div key={i} className="flex justify-between mt-4">
              <div className="flex">
                <p className="h-6 w-6 bg-dark-gray border-2  rounded-full border-dashed mr-4"></p>
                <h1 className="font-medium"> {u}</h1>
              </div>
              <p className="text-bright-Gray">Invited!</p>
            </div>
          );
        })}
      </div>
      <div className="flex justify-between items-center mt-8 lg:w-4/5 md:w-full">
        {/* <Button className="rounded-sm px-12 py-1 text-white bg-dark-green">
          Finish
        </Button> */}
        {/* <p className="text-dark-blue underline font-medium">Skip for Now</p> */}
      </div>
    </div>
  );
};

export default InviteMember;
