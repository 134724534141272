import apiHeader from "./api-headers";
import { apiInstance } from "./axios-service";

export const getLabels = async (payload) => {
  return apiInstance.post("label/list-labels", payload, {
    headers: apiHeader(),
  });
};

export const addLabel = async (payload) => {
  return apiInstance.post("label/create-label", payload, {
    headers: apiHeader(),
  });
};
