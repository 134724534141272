import apiHeader from "./api-headers";
import { apiInstance } from "./axios-service";

export const getSessions = async (payload) => {
  return apiInstance.post("session/list-sessions", payload, {
    headers: apiHeader(),
  });
};

export const getSessionsById = async (payload) => {
  return apiInstance.post("session/get-session", payload, {
    headers: apiHeader(),
  });
};

export const assignLabelToSession = async (payload) => {
  return apiInstance.post("session/assign-label", payload, {
    headers: apiHeader(),
  });
};

export const removeLabelToSession = async (payload) => {
  return apiInstance.post("session/remove-label", payload, {
    headers: apiHeader(),
  });
};

export const markAsArchive = async (payload) => {
  return apiInstance.post("session/archive", payload, { headers: apiHeader() });
};

export const starredSession = async (payload) => {
  return apiInstance.post("session/star", payload, { headers: apiHeader() });
};

export const unStarredSession = async (payload) => {
  return apiInstance.post("session/unstar", payload, { headers: apiHeader() });
};

export const unReadSession = async (payload) => {
  return apiInstance.post("session/mark-unread", payload, {
    headers: apiHeader(),
  });
};

export const readSession = async (payload) => {
  return apiInstance.post("session/mark-read", payload, {
    headers: apiHeader(),
  });
};
