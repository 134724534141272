import React from "react";
import {
  BarchartIcon,
  IconpeopleIcon,
  QuestionmarkIcon,
  VectorIcon,
  ChatIcon,
} from "../../icons/index";
import { Label, Input, Button } from "@windmill/react-ui";
import { useNavigate } from "react-router-dom";

const Sidebar = ({ isExpanded, isExpandedMob, setExpandedMob }) => {
  const navigate = useNavigate();

  return (
    <div className="sidebar-div">
      <div
        className={`sidebar bg-regal-blue fixed top-20  ${
          isExpandedMob ? `slide-nav-1 ml-0` : "slide-nav "
        } ${isExpanded ? "collapse-sidebar w-20" : "w-56"}`}
        id="sidebar"
      >
        <div
          className="sidebar-inner  overflow-auto"
          style={{ height: "calc(100vh)" }}
        >
          <div id="sidebar-menu" className="sidebar-menu">
            <ul className="text-sm list-none m-0 relative ">
              {!isExpanded && (
                <li className="mb-1 relative flex justify-between  px-5 h-16 items-center border-b-2 border-dark-gray">
                  <Input placeHolder="Search" className="rounded-full" />
                </li>
              )}
              <li className="mb-1 relative flex justify-between  px-5 h-16 items-center border-b-2 border-dark-gray">
                <h2
                  className={`text-base font-medium text-white ${
                    isExpanded ? "hidden" : ""
                  } `}
                >
                  Dashboard
                </h2>
                <div className="h-10 w-10 flex justify-center items-center">
                  <BarchartIcon className="text-white" />
                </div>
              </li>
              <li
                className="mb-1 relative flex justify-between  px-5 h-16 items-center border-b-2 border-dark-gray"
                onClick={() => navigate("/conversations")}
              >
                <h2
                  className={`text-base font-medium text-white ${
                    isExpanded ? "hidden" : ""
                  } `}
                >
                  Coversations
                </h2>
                <div className="h-10 w-10 flex justify-center items-center">
                  <ChatIcon className="text-white" />
                </div>
              </li>
              <li
                onClick={() => navigate("/agents")}
                className="mb-1 relative flex justify-between  px-5 h-16 items-center border-b-2 border-dark-gray"
              >
                <h2
                  className={`text-base font-medium text-white ${
                    isExpanded ? "hidden" : ""
                  } `}
                >
                  Agents
                </h2>
                <div className="h-10 w-10 flex justify-center items-center">
                  <VectorIcon className="text-white" />
                </div>
              </li>
              <li
                className="mb-1 relative flex justify-between  px-5 h-16 items-center border-b-2 border-dark-gray"
                onClick={() => navigate("/team-member")}
              >
                <h2
                  className={`text-base font-medium text-white ${
                    isExpanded ? "hidden" : ""
                  } `}
                >
                  Team
                </h2>
                <div className="h-10 w-10 flex justify-center items-center">
                  <IconpeopleIcon className="text-white" />
                </div>
              </li>
              <li className="mb-1 relative flex justify-between  px-5 h-16 items-center border-b-2 border-dark-gray">
                <h2
                  className={`text-base font-medium text-white ${
                    isExpanded ? "hidden" : ""
                  } `}
                >
                  Help Center
                </h2>
                <div className="h-10 w-10 flex justify-center items-center">
                  <QuestionmarkIcon className="text-white" />
                </div>
              </li>
            </ul>
            {!isExpanded && (
              <div
                style={{
                  position: "absolute",
                  bottom: "80px",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <p className="text-white">Karmaflow.ai © 2024</p>
                <p className="text-white"> All Rights Reserved</p>
              </div>
            )}
          </div>
        </div>
      </div>
      {isExpandedMob && (
        <div
          className={`sidebar-overlay h-full w-full z-10 fixed left-0 ${
            isExpandedMob ? "opened block" : "hidden"
          }`}
          style={{
            backgroundColor: "rgba(89, 95, 147, .3 )",

            top: "60px",
          }}
        />
      )}
    </div>
  );
};

export default Sidebar;
