import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/authContext";
import toast from "react-hot-toast";
import { getSetupInfo } from "../../services/setup-service";

const VerifyEmail = () => {
  const { sendVerificationEmail, user, tenantId } = useAuth();
  const [setupData, setSetupData] = useState({});
  const getOrganizationDetail = async () => {
    let payload = {
      tenantId: tenantId,
    };
    const res = await getSetupInfo(payload);
    if (res && res.status === 200) {
      if (res?.data) {
        setSetupData(res?.data);
      }
    }
  };

  useEffect(() => {
    getOrganizationDetail();
  }, []);

  const resendEmail = async () => {
    const res = await sendVerificationEmail();
    console.log(res);
    toast.success("Email has been sent to your registered email address");
  };
  return (
    <div className="ml-64 px-12">
      <h2 className="my-10 text-3xl font-semibold text-dark-black">
        Email Verification
      </h2>
      {setupData?.email ? (
        <p className="text-bright-Gray text-lg">
          Your Email has been verified!
        </p>
      ) : (
        <>
          <p className="text-bright-Gray text-lg">
            We wanna make sure that it’s really you so that we can help you keep
            your account secure.
            <br />
            <br />
            Click on the link sent to you on your email to verify your email
            address.
            <br />
            <br />
            Didn’t receive the email yet?
          </p>
          <button
            onClick={() => resendEmail()}
            className="mt-8 rounded-md px-10 py-2 text-white bg-bright-orange"
          >
            Resend Email
          </button>
        </>
      )}
    </div>
  );
};

export default VerifyEmail;
