import apiHeader from "./api-headers";
import { apiInstance } from "./axios-service";

export const createAgent = async (payload) => {
  return apiInstance.post("agent/create-agent", payload, {
    headers: apiHeader(),
  });
};

export const updateAgentName = async (payload) => {
  return apiInstance.post("agent/update-agent-name", payload, {
    headers: apiHeader(),
  });
};

export const getAgentById = async (payload) => {
  return apiInstance.post("agent/get-agent", payload, {
    headers: apiHeader(),
  });
};

export const listAgents = async (payload) => {
  return apiInstance.post("agent/list-agents", payload, {
    headers: apiHeader(),
  });
};
