import React from "react";
import { createAgent } from "../../services/agent-service";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/authContext";

const GetStarted = () => {
  const navigate = useNavigate();
  const { tenantId } = useAuth();
  const makeAgent = async () => {
    let payload = {
      tenantId: tenantId,
      name: "New Agent",
    };
    const res = await createAgent(payload);
    if (res && res.status === 200) {
      navigate("/agent/" + res.data.data.agentId);
    }
  };
  return (
    <React.Fragment>
      <div className="flex justify-center items-center h-full">
        <div
          className="text-center border-2 border-dark-gray"
          style={{ padding: "190px 100px" }}
        >
          <h1 className="text-4xl font-semibold text-dark-black">
            Let’s Get You Started!
          </h1>
          <p className="text-bright-Gray my-5 py-3 pb-5 text-sm">
            {" "}
            You’re one step away from accelerating your sales journey.
            <br /> Start by creating your first AI Sales Agent.
          </p>
          <button
            className="h-12 w-48 bg-green text-white mt-5 rounded-lg shadow-2xl focus:outline-none"
            onClick={() => makeAgent()}
          >
            Create Agent
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default GetStarted;
