import React, { useEffect, useState } from "react";
import Arrow from "../../icons/arrow.svg";
import { Dropdown, DropdownItem, Button, Input } from "@windmill/react-ui";
import { useAuth } from "../../context/authContext";
import toast from "react-hot-toast";
import { getSetupInfo, setMobileVerified } from "../../services/setup-service";
const VerifyNumber = () => {
  const {
    sendVerificationCode,
    tenantId,
    verifyVerificationCode,
    checkIsUserExist,
  } = useAuth();
  const [userDetail, setUserDetails] = useState();
  const [otp, setOtp] = useState();
  const [confirmResult, setConfirmResult] = useState();
  const [setupData, setSetupData] = useState({});
  const getOrganizationDetail = async () => {
    let payload = {
      tenantId: tenantId,
    };
    const res = await getSetupInfo(payload);
    if (res && res.status === 200) {
      if (res?.data) {
        setSetupData(res?.data);
      }
    }
  };

  const checkUser = async () => {
    const result = await checkIsUserExist();

    if (result?.status == 200) {
      setUserDetails(result.data.userInfo);
    } else {
      toast.error("Something went wrong!");
    }
  };

  const sendOtp = async () => {
    if (userDetail) {
      const res = await sendVerificationCode(
        "+" + userDetail.cc + userDetail.phoneNumber
      );
      console.log(res);
      setConfirmResult(res);
    }
  };

  const handleClick = async (e) => {
    e.preventDefault();
    const res = await verifyVerificationCode(confirmResult, otp);
    console.log(res);
    if (res && res.user) {
      const result = await setMobileVerified({ userId: res.user.uid });
      console.log(result);
      if (result && result.status === 200) {
        toast.success(result.data.message);
        getOrganizationDetail();
      }
    }
  };

  useEffect(() => {
    checkUser();
    getOrganizationDetail();
  }, []);
  return (
    <div className="ml-64 px-12">
      <h2 className="my-10 text-3xl font-semibold text-dark-black">
        Phone Number Verification
      </h2>
      {setupData.phone ? (
        <p className="text-bright-Gray text-lg">
          Your Phone number is already verified.
        </p>
      ) : (
        <>
          {confirmResult && (
            <>
              <form onSubmit={(e) => handleClick(e)}>
                <p className="text-bright-Gray text-lg">
                  We wanna make sure that it’s really you so that we can help
                  you keep your account secure.
                  <br />
                  <br />
                  <span className="font-semibold">
                    Enter the code received on your SMS
                  </span>
                </p>
                <div className="grid lg:grid-cols-2 md:grid-cols-1">
                  <div
                    className="flex justify-between rounded-full w-full bg-white my-5 px-5 py-2"
                    style={{ boxShadow: "#E5E6FF 3px 0px 13px 0px" }}
                  >
                    <Input
                      type="text"
                      placeholder="Verification Code"
                      value={otp}
                      required
                      onChange={(e) => setOtp(e.target.value)}
                      className="focus:outline-none border-none w-full "
                    />
                    <Button
                      layout="link"
                      type="submit"
                      className={"bg-transparent"}
                    >
                      {" "}
                      <img src={Arrow} />
                    </Button>
                  </div>
                </div>
              </form>
            </>
          )}

          <p className="font-semibold text-bright-Gray text-lg pt-5">
            Didn’t receive the SMS yet?
          </p>
          <button
            className="mt-5 rounded-md px-10 py-2 text-white bg-bright-orange"
            onClick={() => sendOtp()}
          >
            Resend Code
          </button>
        </>
      )}
    </div>
  );
};

export default VerifyNumber;
