import React, { useState, useEffect } from "react";
import checkCircle from "../../assets/img/checkCircle.png";
import { getSetupInfo } from "../../services/setup-service";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/authContext";
const OrganizationSetup = () => {
  const [loading, setLoading] = useState(true);
  const [setupData, setSetupData] = useState({});
  const navigate = useNavigate();
  const { tenantId } = useAuth();
  const continueSetup = () => {
    if (!setupData.email) {
      navigate("/setup/verify-email");
      return;
    } else if (!setupData.phone) {
      navigate("/setup/verify-number");
      return;
    } else if (!setupData.billing) {
      navigate("/setup/billing");
      return;
    }
  };
  const getOrganizationDetail = async () => {
    let payload = {
      tenantId: tenantId,
    };
    setLoading(true);
    const res = await getSetupInfo(payload);
    setLoading(false);
    if (res && res.status === 200) {
      if (res?.data) {
        setSetupData(res?.data);
      }
    }
  };
  useEffect(() => {
    getOrganizationDetail();
  }, []);

  return (
    <React.Fragment>
      <div className="flex justify-center mt-24">
        <div className="border-2 border-gray lg:p-10 py-12 lg:w-4/6 md:w-full md:mx-5 md:px-3 ">
          <h1 className="text-3xl text-center text-dark-black font-semibold">
            Set up Karmaflow for your Organization
          </h1>
          {loading ? (
            <div className="flex justify-center mt-5">
              <span className="loader"></span>
            </div>
          ) : (
            <>
              <div className="lg:grid md:grid sm:flex grid-cols-2 mt-4 organization-div">
                <div className="px-5 first-div border-r-2 border-bright-Gray">
                  <p className="mb-10 text-base text-bright-Gray px-6">
                    You’re missing out on cool stuff! Complete your account
                    setup to deploy your agent and unlock other exciting
                    features! 🚀
                  </p>
                  <div className="px-6">
                    <button
                      onClick={() => continueSetup()}
                      className="rounded-md px-12 py-2 text-white bg-bright-orange mb-4"
                    >
                      Continue Setup
                    </button>
                    <button className=" rounded-md px-10 py-2 text-white bg-dark-yellow">
                      Go To Playground
                    </button>
                  </div>
                </div>
                <div className="px-5 sidebar-list-div">
                  <ul className="text-sm list-none m-0 relative ">
                    <li className="mb-1 relative flex  px-5 h-8 items-center text-bright-Gray">
                      <img src={checkCircle} className="h-6 w-6 mr-3 mt-1" />
                      <span>Create an Organization Account </span>
                    </li>
                    <li className="mb-1 relative flex  px-5 h-8 items-center text-bright-Gray">
                      <img src={checkCircle} className="h-6 w-6 mr-3 mt-1" />

                      <p>Add Organization Details</p>
                    </li>
                    {setupData.hasOwnProperty("agent") && (
                      <li className="mb-1 relative flex  px-5 h-8 items-center text-bright-Gray">
                        {setupData?.agent ? (
                          <img
                            src={checkCircle}
                            className="h-6 w-6 mr-3 mt-1"
                          />
                        ) : (
                          <p className="h-5 w-5 border-2 border-bright-Gray rounded-full mr-3"></p>
                        )}

                        <h4>Create Your First Agent</h4>
                      </li>
                    )}
                    {setupData.hasOwnProperty("email") && (
                      <li className="mb-1 relative flex  px-5 h-8 items-center text-bright-Gray">
                        {setupData?.email ? (
                          <img
                            src={checkCircle}
                            className="h-6 w-6 mr-3 mt-1"
                          />
                        ) : (
                          <p className="h-5 w-5 border-2 border-bright-Gray rounded-full mr-3"></p>
                        )}
                        <h4>Verify your Email</h4>
                      </li>
                    )}
                    {setupData.hasOwnProperty("phone") && (
                      <li className="mb-1 relative flex  px-5 h-8 items-center text-bright-Gray">
                        {setupData?.phone ? (
                          <img
                            src={checkCircle}
                            className="h-6 w-6 mr-3 mt-1"
                          />
                        ) : (
                          <p className="h-5 w-5 border-2 border-bright-Gray rounded-full mr-3"></p>
                        )}
                        <h4>Verify your Phone Number</h4>
                      </li>
                    )}
                    {setupData.hasOwnProperty("billing") && (
                      <li className="mb-1 relative flex  px-5 h-8 items-center text-bright-Gray">
                        {setupData?.billing ? (
                          <img
                            src={checkCircle}
                            className="h-6 w-6 mr-3 mt-1"
                          />
                        ) : (
                          <p className="h-5 w-5 border-2 border-bright-Gray rounded-full mr-3"></p>
                        )}
                        <h4>Setup Billing</h4>
                      </li>
                    )}
                    <li className="mb-1 relative flex  px-5 h-8 items-center text-bright-Gray">
                      <p className="h-5 w-5 border-2 border-bright-Gray rounded-full mr-3"></p>
                      <h4>Invite Team Members (Optional)</h4>
                    </li>
                  </ul>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default OrganizationSetup;
