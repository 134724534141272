import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Table,
  TableHeader,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  Avatar,
  Pagination,
  Input,
  Label,
  Select,
  Badge,
} from "@windmill/react-ui";
import { StarIcon, ActiveStarIcon } from "../../icons";
import { useAuth } from "../../context/authContext";
import { getSessions } from "../../services/session-service";
import { useNavigate } from "react-router-dom";
import { listAgents } from "../../services/agent-service";

const Agents = () => {
  const { tenantId } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [payload, setPayload] = useState({
    tenantId: "",
  });
  const [sessions, setSessions] = useState([]);

  const getAgents = async () => {
    setIsLoading(true);
    const res = await listAgents(payload);
    console.log(res);
    if (res && res.status == 200) {
      setSessions(res.data.agents);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (tenantId) {
      setPayload({ ...payload, tenantId: tenantId });
    }
  }, [tenantId]);

  useEffect(() => {
    if (payload.tenantId) {
      getAgents();
    }
  }, [payload]);

  return (
    <div className="p-4 mt-4">
      <div className="flex  justify-between items-center">
        <p className="text-xl" style={{ color: "#303030" }}>
          Agents
        </p>
      </div>
      {isLoading ? (
        <div className="flex justify-center mt-5">
          <span className="loader"></span>
        </div>
      ) : (
        <TableContainer className="mt-4" style={{ boxShadow: "none" }}>
          {/* <Pagination
            totalResults={10}
            resultsPerPage={4}
            onChange={() => {}}
            label="Table navigation"
          /> */}
          <Table className="mt-4 w-full">
            <TableHeader style={{ borderColor: "#BEBEBE" }}>
              <TableRow>
                <TableCell style={{ color: "#303030" }}>
                  {/* <Input
                    style={{
                      width: "16px",
                      height: "16px",
                      marginBottom: "3px",
                    }}
                    type="checkbox"
                  />{" "} */}
                  <span className="text-sm">ID</span>
                </TableCell>
                <TableCell style={{ color: "#303030" }}>Agent Name</TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {sessions.map((s, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell>
                      {/* <Input type="checkbox" />{" "} */}
                      <span
                        className="text-sm cursor-pointer pointer"
                        // onClick={() =>
                        //   navigate("/conversation-details/" + s.agentId)
                        // }
                      >
                        {s.agentId}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="font-semibold ">{s?.name}</span>
                    </TableCell>

                    <TableCell>
                      <span className="font-semibold ">{s?.agent?.name}</span>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default Agents;
