import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Table,
  TableHeader,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  Avatar,
  Pagination,
  Input,
  Button,
  Label,
  Dropdown,
  DropdownItem,
  Select,
  Badge,
} from "@windmill/react-ui";
import {
  StarIcon,
  ActiveStarIcon,
  DotIcon,
  ResetIcon,
  SortIcon,
  LabelIcon,
} from "../../icons";
import { useAuth } from "../../context/authContext";
import {
  getSessions,
  markAsArchive,
  readSession,
  starredSession,
  unReadSession,
  unStarredSession,
} from "../../services/session-service";
import { useNavigate } from "react-router-dom";
import Arrow from "../../icons/arrow.svg";
import { listAgents } from "../../services/agent-service";
import { getLabels } from "../../services/label-service";

const Conversations = () => {
  const { tenantId } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState();
  var colors = ["red", "green", "blue", "orange", "yellow"];
  const [showTags, setShowTags] = useState(false);
  const [payload, setPayload] = useState({
    tenantId: "",
    agentId: "",
    maxResults: 10,
    pageNumber: 0,
    sortByColumn: "createdAt",
    sortOrder: "desc",
    filters: { labels: [], visitorNameSearchTerm: undefined },
  });
  const [showOptions, setShowOptions] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [agents, setAgents] = useState([]);
  const [labels, setLabels] = useState();
  const [search, setSearch] = useState("");

  const getConversations = async () => {
    setIsLoading(true);
    const res = await getSessions(payload);
    console.log(res);
    if (res && res.status == 200) {
      const temp = res?.data?.data?.sessions?.map((x) => {
        x["isChecked"] = false;
        return x;
      });
      setSessions(temp);
      setTotal(res.data.data.totalCount);
    }
    setIsLoading(false);
  };

  const getAgents = async () => {
    setIsLoading(true);
    const res = await listAgents({ tenantId: tenantId });
    console.log(res);
    if (res && res.status == 200) {
      setAgents(res.data.agents);
    }
    setIsLoading(false);
  };

  const onSort = (type) => {
    setPayload({
      ...payload,
      sortByColumn: type,
      sortOrder: payload.sortOrder == "desc" ? "asc" : "desc",
    });
  };

  const onBulkActions = async (type) => {
    switch (type) {
      case "archive":
        if (sessions.filter((x) => x.isChecked).length > 0) {
          const temp = sessions.filter((x) => x.isChecked);
          for (let i = 0; i < temp.length; i++) {
            const res = await markAsArchive({
              tenantId: tenantId,
              sessionId: temp[i].sessionId,
            });
            if (res && res.status == 200) {
              if (i === temp.length - 1) {
                getConversations();
              }
            }
          }
        }
        break;

      case "unread":
        if (sessions.filter((x) => x.isChecked).length > 0) {
          const temp = sessions.filter((x) => x.isChecked);
          for (let i = 0; i < temp.length; i++) {
            const res = await unReadSession({
              tenantId: tenantId,
              sessionId: temp[i].sessionId,
            });
            if (res && res.status == 200) {
              if (i === temp.length - 1) {
                getConversations();
              }
            }
          }
        }
        break;

      case "read":
        if (sessions.filter((x) => x.isChecked).length > 0) {
          const temp = sessions.filter((x) => x.isChecked);
          for (let i = 0; i < temp.length; i++) {
            const res = await readSession({
              tenantId: tenantId,
              sessionId: temp[i].sessionId,
            });
            if (res && res.status == 200) {
              if (i === temp.length - 1) {
                getConversations();
              }
            }
          }
        }
        break;

      default:
        break;
    }
  };

  const onPageChange = (page) => {
    console.log(page);
    if (page != payload.pageNumber + 1) {
      setPayload({ ...payload, pageNumber: page + 1 });
    }
  };

  useEffect(() => {
    if (tenantId) {
      setPayload({ ...payload, tenantId: tenantId });
    }
  }, [tenantId]);

  const toggleStarred = async (session) => {
    if (session.starred) {
      const res = await unStarredSession({
        tenantId: tenantId,
        sessionId: session.sessionId,
      });
      if (res && res.status == 200) {
        getConversations();
      }
    } else {
      const res = await starredSession({
        tenantId: tenantId,
        sessionId: session.sessionId,
      });
      if (res && res.status == 200) {
        getConversations();
      }
    }
  };

  const getTags = async () => {
    const res = await getLabels({ tenantId: tenantId });
    console.log(res);
    if (res && res.status == 200) {
      setLabels(res.data.data);
    }
  };

  useEffect(() => {
    getTags();
  }, []);

  useEffect(() => {
    if (payload.tenantId) {
      getConversations();
      getAgents();
      getTags();
    }
  }, [payload]);

  const selectAllSession = (e) => {
    let temp = Object.assign([], sessions);
    temp = temp?.map((x) => {
      x["isChecked"] = e.target.checked;
      return x;
    });
    setSessions(temp);
  };

  return (
    <div className="p-4 mt-4" style={{ height: "calc(100% - 1rem)" }}>
      <div className="flex  justify-between items-center">
        <p className="text-xl" style={{ color: "#303030", fontWeight: 700 }}>
          Conversations{" "}
          <span
            className="text-sm"
            style={{ color: "#303030", fontWeight: 400 }}
          >
            {total ? total + " Chats" : ""}
          </span>
        </p>
        <Label>
          <span>Agents</span>
          <Select
            className="mt-1 rounded-lg p-0"
            onChange={(e) => {
              setPayload({
                ...payload,
                agentId: e.target.value,
                pageNumber: 0,
              });
            }}
            style={{ height: "32px", width: "150px", paddingLeft: "8px" }}
            value={payload.agentId}
          >
            <option value={""}>All</option>
            {agents?.map((a, i) => {
              return (
                <option value={a.agentId} key={i}>
                  {a.name}
                </option>
              );
            })}
          </Select>
        </Label>
      </div>

      <TableContainer
        className="mt-4"
        style={{ boxShadow: "none", height: "calc(100% - 4rem)" }}
      >
        <div className="flex items-center justify-between">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              const temp = Object.assign({}, payload);
              temp.filters.visitorNameSearchTerm = search ? search : undefined;
              setPayload(temp);
            }}
            className="flex items-center"
          >
            <Label className="flex items-center">
              <Input
                type="text"
                className="rounded-lg"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                style={{ width: "250px" }}
                placeholder="Search"
              />
              <Button
                layout="link"
                style={{ marginLeft: "-50px" }}
                type="submit"
              >
                {" "}
                <img src={Arrow} />
              </Button>
            </Label>
            <div
              style={{ color: "#303030" }}
              className="text-sm px-1 flex items-center relative"
            >
              {" "}
              <LabelIcon
                onClick={() => setShowTags(!showTags)}
                className="mr-1"
              />
              <Dropdown
                className="mt-4"
                isOpen={showTags}
                onClose={() => setShowTags(false)}
              >
                {labels?.map((l) => {
                  return (
                    <DropdownItem
                      key={l.labelId}
                      tag="a"
                      className="justify-between"
                    >
                      <Input
                        type="checkbox"
                        name={"check_" + l.labelId}
                        checked={payload?.filters?.labels?.includes(l.labelId)}
                        onChange={(e) => {
                          console.log(e.target.checked);
                          if (e.target.checked) {
                            const temp = Object.assign({}, payload);
                            if (!temp.filters.labels.includes(l.labelId)) {
                              temp.filters.labels.push(l.labelId);
                              setPayload(temp);
                            }
                          } else {
                            const temp = Object.assign({}, payload);
                            let i = temp.filters.labels.findIndex(
                              (x) => x == l.labelId
                            );
                            console.log(i);
                            temp.filters.labels.splice(i, 1);
                            setPayload(temp);
                          }
                        }}
                      />{" "}
                      <span>{l.name}</span>
                    </DropdownItem>
                  );
                })}
              </Dropdown>
            </div>
          </form>
          <div className="flex items-center relative">
            <Button
              layout="link"
              aria-haspopup="true"
              onClick={() => setShowOptions(!showOptions)}
              type="button"
              style={{ paddingLeft: "0.5rem", paddingRight: "0.5rem" }}
            >
              <DotIcon />
            </Button>
            <Dropdown
              isOpen={showOptions}
              onClose={() => {
                setShowOptions(false);
              }}
              className="mt-5"
            >
              <DropdownItem
                tag="a"
                onClick={() => onBulkActions("read")}
                className="justify-between"
              >
                <span>Mark as Read</span>
              </DropdownItem>
              <DropdownItem
                tag="a"
                onClick={() => onBulkActions("unread")}
                className="justify-between"
              >
                <span>Mark as UnRead</span>
              </DropdownItem>
              <DropdownItem tag="a" onClick={() => onBulkActions("archive")}>
                <span>Archive</span>
              </DropdownItem>
            </Dropdown>
            <Button
              layout="link"
              onClick={() =>
                // setPayload({
                //   tenantId: tenantId,
                //   agentId: "",
                //   maxResults: 10,
                //   pageNumber: 0,
                //   sortByColumn: "createdAt",
                //   sortOrder: "desc",
                //   filters: {
                //     visitorNameSearchTerm: undefined,
                //     labels: undefined,
                //   },
                // })
                getConversations()
              }
              type="button"
            >
              <ResetIcon />
            </Button>

            <Pagination
              totalResults={total}
              resultsPerPage={10}
              onChange={(e) => {
                onPageChange(e);
              }}
              id={"table-page"}
              // className={"pagination"}
              label="Table navigation"
            />
          </div>
        </div>
        {isLoading ? (
          <div className="flex justify-center mt-5">
            <span className="loader"></span>
          </div>
        ) : (
          <Table className="mt-4 w-full">
            <TableHeader style={{ borderColor: "#BEBEBE" }}>
              <TableRow>
                <TableCell style={{ color: "#303030" }}>
                  <Input
                    style={{
                      width: "16px",
                      height: "16px",
                      marginBottom: "3px",
                    }}
                    checked={
                      sessions?.findIndex((x) => !x.isChecked) > -1
                        ? false
                        : true
                    }
                    onChange={(e) => selectAllSession(e)}
                    type="checkbox"
                  />{" "}
                  <span className="text-sm">ID</span>
                </TableCell>
                <TableCell style={{ color: "#303030" }}>
                  <span style={{ display: "flex" }}>
                    Name <SortIcon onClick={() => onSort("name")} />
                  </span>
                </TableCell>
                <TableCell style={{ color: "#303030" }}>
                  <span style={{ display: "flex" }}>
                    Date <SortIcon onClick={() => onSort("createdAt")} />
                  </span>
                </TableCell>
                <TableCell style={{ color: "#303030" }}>Agent Name</TableCell>
                <TableCell style={{ color: "#303030" }}>Labels</TableCell>
                <TableCell style={{ color: "#303030" }}></TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {sessions.map((s, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell>
                      <Input
                        type="checkbox"
                        name={"check" + s?.sessionId}
                        onChange={(e) => {
                          const temp = Object.assign([], sessions);
                          const i = temp.findIndex(
                            (x) => x.sessionId === s.sessionId
                          );
                          temp[i].isChecked = e.target.checked;
                          setSessions(temp);
                        }}
                        checked={s?.isChecked}
                      />{" "}
                      <span
                        className="text-sm cursor-pointer pointer"
                        onClick={() =>
                          navigate("/conversation-details/" + s.sessionId)
                        }
                      >
                        {s.sessionId.substr(s.sessionId.length - 10)}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span
                        className={s?.read ? "font-normal" : "font-semibold"}
                      >
                        {s?.visitor?.name}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span
                        className={s?.read ? "font-normal" : "font-semibold"}
                      >
                        {new Date(s.createdAt)?.toLocaleString()}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span
                        className={s?.read ? "font-normal" : "font-semibold"}
                      >
                        {s?.agent?.name}
                      </span>
                    </TableCell>
                    <TableCell>
                      {s?.labels?.map((l) => {
                        return (
                          <Badge
                            style={{
                              background:
                                colors[
                                  Math.floor(Math.random() * colors.length)
                                ],
                            }}
                            type="success"
                          >
                            {l.name}
                          </Badge>
                        );
                      })}
                    </TableCell>
                    <TableCell>
                      <span
                        className="font-semibold "
                        onClick={() => toggleStarred(s)}
                      >
                        {s?.starred ? <ActiveStarIcon /> : <StarIcon />}
                      </span>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </div>
  );
};

export default Conversations;
