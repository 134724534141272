import React from "react";
import { LockIcon } from "../../icons";
import { Select, Button, Input } from "@windmill/react-ui";

const BillingSetup = () => {
  return (
    <div className="ml-64 px-12">
      <h2 className="my-10 text-3xl font-semibold text-dark-black">
        Billing Information
      </h2>
      <p className="text-bright-Gray text-lg">
        Let’s update your billing information. This will unlock the Free Plan
        and will allow you to use Karmaflow as long as you want.
        <br />
        <br />
        <span className="font-semibold">
          Don’t worry, you won’t be charged until you select a plan later.
        </span>
      </p>
      <div className="lg:w-4/6 md:w-full mt-5">
        <div className="w-full">
          <p>Card number</p>
          <Input
            type="number"
            placeholder="1234 1234 1234 1234"
            className="bg-gray h-10 rounded-lg w-full mt-1 focus:outline-none px-3"
          />
        </div>
        <div className="w-full grid grid-cols-2 gap-4 mt-5">
          <div>
            <p>Expiration</p>
            <Input
              type="number"
              placeholder="MM/YY"
              className="bg-gray h-10 rounded-lg w-full mt-1 focus:outline-none px-3"
            />
          </div>
          <div>
            <p>CVC</p>
            <Input
              type="number"
              placeholder="CVC"
              className="bg-gray h-10 rounded-lg w-full mt-1 focus:outline-none px-3"
            />
          </div>
          <div>
            <p>Country</p>
            <div className="relative">
              <Select className="mt-1 border-gray shadow-outline-none bg-gray focus:shadow-outline-none active:shadow-outline-none focus:outline-none focus:border-none active:border-none">
                <option>India</option>
                <option>Pakistan</option>
                <option>Afghanistan</option>
                <option>America</option>
              </Select>
            </div>
          </div>
          <div>
            <p>Postal code</p>
            <Input
              type="number"
              placeholder="MST IT4"
              className="bg-gray h-10 rounded-lg w-full mt-1 focus:outline-none px-3"
            />
          </div>
        </div>
        <div className=" mt-8 ">
          <Button className="rounded-sm flex justify-center w-full py-1 text-white bg-dark-green">
            <LockIcon className="mr-3 mt-1" />
            Securely Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BillingSetup;
