import React, { useEffect, useState } from "react";
import {
  ActiveStarIcon,
  BuildingIcon,
  CalendorIcon,
  CallIcon,
  LabelIcon,
  LeftArrowIcon,
  MailIcon,
  RobotIcon,
  StarIcon,
  UserIcon,
  VectorBlack,
} from "../../icons";
import {
  Card,
  Dropdown,
  DropdownItem,
  Input,
  Modal,
  Label,
  Button,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "@windmill/react-ui";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/authContext";
import {
  assignLabelToSession,
  getSessionsById,
  readSession,
  removeLabelToSession,
  starredSession,
  unStarredSession,
} from "../../services/session-service";
import { addLabel, getLabels } from "../../services/label-service";

const ConversationDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { tenantId } = useAuth();
  const [session, setSession] = useState(null);
  const [labels, setLabels] = useState(null);
  const [label, setLabel] = useState(null);
  const [showTags, setShowTags] = useState(false);

  const [addModal, setAddModal] = useState(false);

  const getConversationById = async () => {
    const res = await getSessionsById({ tenantId: tenantId, sessionId: id });
    console.log(res);
    if (res && res.status == 200) {
      setSession(res.data.data);
    }
  };

  const getTags = async () => {
    const res = await getLabels({ tenantId: tenantId });
    console.log(res);
    if (res && res.status == 200) {
      setLabels(res.data.data);
    }
  };

  const onAddLabel = async () => {
    const res = await addLabel({ tenantId: tenantId, name: label });

    if (res && res.status == 200) {
      setAddModal(false);
      getTags();
    }
  };

  const assignLabel = async (e, lid) => {
    if (e.target.checked) {
      const res = await assignLabelToSession({
        tenantId: tenantId,
        sessionId: id,
        labelId: lid,
      });
      if (res && res.status == 200) {
        getTags();
      }
    } else {
      const res = await removeLabelToSession({
        tenantId: tenantId,
        sessionId: id,
        labelId: lid,
      });
      if (res && res.status == 200) {
        getTags();
      }
    }
  };

  const toggleStarred = async (session) => {
    if (session.starred) {
      const res = await unStarredSession({
        tenantId: tenantId,
        sessionId: session.sessionId,
      });
      if (res && res.status == 200) {
        getConversationById();
      }
    } else {
      const res = await starredSession({
        tenantId: tenantId,
        sessionId: session.sessionId,
      });
      if (res && res.status == 200) {
        getConversationById();
      }
    }
  };

  const makeRead = async () => {
    const res = await readSession({
      tenantId: tenantId,
      sessionId: id,
    });
    if (res && res.status == 200) {
    }
  };

  useEffect(() => {
    getConversationById();
    getTags();
    makeRead();
  }, []);

  return (
    <div className="p-4 mt-4">
      <div
        className="flex items-center pb-2"
        onClick={() => navigate("/conversations")}
      >
        <LeftArrowIcon />{" "}
        <p
          style={{ color: "#303030", fontWeight: 600 }}
          className="text-sm mx-2"
        >
          Back to the list
        </p>
      </div>
      <div className="flex items-center mt-5 pb-3 divide-x">
        <p style={{ color: "#303030" }} className="text-sm px-1">
          ID: {session?.visitor?.visitorId}
        </p>
        <p
          style={{ color: "#303030" }}
          className="text-sm px-1 flex items-center"
        >
          {" "}
          <CalendorIcon className="mr-1" />
          {new Date(session?.createdAt)?.toLocaleString()}
        </p>
        <p
          style={{ color: "#303030" }}
          className="text-sm px-1 flex items-center"
        >
          {" "}
          <VectorBlack className="mr-1" /> {session?.agent?.name}
        </p>
        <p
          style={{ color: "#303030" }}
          className="text-sm px-1 flex items-center"
          onClick={() => toggleStarred(session)}
        >
          {session?.starred ? (
            <ActiveStarIcon className="mr-1" />
          ) : (
            <StarIcon className="mr-1" />
          )}
        </p>
        <p
          style={{ color: "#303030" }}
          className="text-sm px-1 flex items-center relative"
        >
          {" "}
          <LabelIcon onClick={() => setShowTags(!showTags)} className="mr-1" />
          <Dropdown
            className="mt-4"
            isOpen={showTags}
            onClose={() => setShowTags(false)}
          >
            {labels?.map((l) => {
              return (
                <DropdownItem
                  key={l.labelId}
                  tag="a"
                  className="justify-between"
                >
                  <Input
                    type="checkbox"
                    checked={session?.labels?.find(
                      (x) => x.labelId === l.labelId
                    )}
                    onChange={(e) => assignLabel(e, l.labelId)}
                  />{" "}
                  <span>{l.name}</span>
                </DropdownItem>
              );
            })}

            <DropdownItem
              onClick={() => {
                setShowTags(false);
                setAddModal(true);
              }}
            >
              <span>Add Label</span>
            </DropdownItem>
          </Dropdown>
        </p>
      </div>
      <div
        className="text-lg mt-3 pb-3"
        style={{ color: "#303030", fontWeight: 600 }}
      >
        {session?.visitor?.name}
      </div>
      <div className="grid grid-cols-2 gap-8 mt-4">
        <div>
          <div>
            <p style={{ color: "#303030" }} className="text-lg mb-2">
              Summary
            </p>
            <Card className="px-4 py-2">
              <p style={{ whiteSpace: "pre-wrap" }}>
                {session?.summary || "N/A"}
              </p>
            </Card>
          </div>
          {/* <div className="mt-5">
            <div>
              <p style={{ color: "#303030" }} className="text-lg mb-2">
                Lead Stage Identification
              </p>
              <Card className="px-4 py-2">
                <ol className="list-disc list-inside">
                  <li className="text-xs">afdfdgd</li>
                  <li className="text-xs">afdfdgd</li>
                  <li className="text-xs">afdfdgd</li>
                </ol>
              </Card>
            </div>
          </div> */}
          {/* <div className="mt-5">
            <div>
              <p style={{ color: "#303030" }} className="text-lg mb-2">
                Custom Card
              </p>
              <Card className="px-4 py-2">
                <ol className="list-disc list-inside">
                  <li className="text-xs">afdfdgd</li>
                  <li className="text-xs">afdfdgd</li>
                  <li className="text-xs">afdfdgd</li>
                </ol>
              </Card>
            </div>
          </div> */}
        </div>
        <div>
          <div>
            <p style={{ color: "#303030" }} className="text-lg mb-2">
              User Details
            </p>
            <Card className="px-4 py-2">
              <ol className="">
                <li className="text-xs flex items-center mb-2">
                  <MailIcon
                    className="mr-3"
                    style={{ width: "20px", height: "20px" }}
                  />{" "}
                  {session?.visitor?.email || "N/A"}
                </li>
                <li className="text-xs flex items-center mb-2">
                  <CallIcon className="mr-3" />{" "}
                  {session?.visitor?.mobile || "N/A"}
                </li>
                <li className="text-xs flex items-center mb-2">
                  <BuildingIcon
                    className="mr-3"
                    style={{ width: "20px", height: "20px" }}
                  />{" "}
                  {session?.visitor?.company || "N/A"}
                </li>
              </ol>
            </Card>
          </div>
          <div className="mt-5">
            <p style={{ color: "#303030" }} className="text-lg mb-2">
              Transcript
            </p>
            <Card>
              <div className="transcript-container">
                {session?.transcript?.map((m, i) => {
                  return m.role == "USER" ? (
                    <div className="assistant-container">
                      <div className="user-icon">
                        <UserIcon />
                      </div>
                      <p className="assistant-title">
                        {" "}
                        {session?.visitor?.name}
                      </p>
                      <p className="msg">{m.message}</p>
                    </div>
                  ) : (
                    <div className="assistant-container">
                      <div className="assistant-icon">
                        <RobotIcon />
                      </div>
                      <p className="assistant-title">Karmaflow Assistant</p>
                      <p className="msg">{m.message}</p>
                    </div>
                  );
                })}
              </div>
            </Card>
          </div>
        </div>
      </div>
      <Modal isOpen={addModal} onClose={() => setAddModal(false)}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onAddLabel();
          }}
        >
          {" "}
          <ModalHeader>Add Label</ModalHeader>
          <ModalBody>
            <Label>
              <span>Label Name</span>
              <Input
                required
                onChange={(e) => setLabel(e.target.value)}
                value={label}
                className="mt-1"
              />
            </Label>
          </ModalBody>
          <ModalFooter>
            <Button
              className="w-full sm:w-auto"
              layout="outline"
              onClick={() => setAddModal(false)}
            >
              Cancel
            </Button>
            <Button type="submit" className="w-full sm:w-auto">
              ADD
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
};

export default ConversationDetails;
