import React, { useState, useEffect } from "react";
import { BuildingIcon, LogoutIcon, MenuIcon, SettingIcon } from "../../icons";
import { Card, Label, Select } from "@windmill/react-ui";
import unnamed from "../../assets/img/unnamed 2.jpg";
import { useAuth } from "../../context/authContext";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/img/LOGO1.png";

const Header = ({ setExpanded, isExpanded, setExpandedMob, isExpandedMob }) => {
  const [openBox, setOpenBox] = useState(false);
  const {
    logOut,
    setIsLoggedIn,
    tenantId,
    setTenantId,
    user,
    setUser,
    setToken,
    checkIsUserExist,
  } = useAuth();
  const [userInfo, setUserInfo] = useState();
  const navigate = useNavigate();
  const logoutUser = async () => {
    const res = await logOut();
    localStorage.clear();
    setUser(null);
    setToken(null);
    setIsLoggedIn(false);
    setTimeout(() => {
      navigate("/login");
    }, 200);
  };
  const checkUser = async () => {
    const result = await checkIsUserExist();
    setUserInfo(result.data);
  };
  useEffect(() => {
    checkUser();
  }, []);

  const userModal = () => {
    setOpenBox(!openBox);
  };
  return (
    <>
      <header className="header header-one flex justify-between items-center h-16 bg-regal-blue px-5 header-div">
        <div className="flex items-center">
          <p
            id="dev_btn h-8 w-8"
            className="dev_btn lg:inline-flex lg:items-center lg:float-left lg:text-xl lg:justify-center lg:cursor-pointer md:hidden"
            onClick={() => setExpanded(!isExpanded)}
          >
            <div className="h-8 w-8">
              <MenuIcon />
            </div>
          </p>
          <p
            className="mobile_btn lg:hidden md:text-black md:cursor-pointer md:flex md:w-16 sm:w-10 md:items-center sm:flex md:block md:text-xl md:left-0 md:px-4 md:absolute md:items-center md:top-0 md:z-10"
            id="mobile_btn"
            style={{ height: "60px" }}
            onClick={() => setExpandedMob(!isExpandedMob)}
          >
            <MenuIcon />
          </p>
          <div
            onClick={() => navigate("/agents")}
            className="logo-typo text-white ml-5 cursor-pointer lg:pl-5 md:pl-6 flex items-center font-semibold text-lg"
          >
            <img style={{ width: "180px" }} src={Logo} />
          </div>
        </div>
        <div className="flex items-center">
          <Label className="mr-2">
            <Select
              value={tenantId}
              onChange={(e) => {
                localStorage.setItem("tenantId", e.target.value);
                setTenantId(e.target.value);
                window.location.reload();
              }}
            >
              {userInfo?.tenants?.map((x, i) => {
                return <option value={x.tenantId}>{x.companyName}</option>;
              })}
            </Select>
          </Label>
          <Card
            className="flex justify-center items-center py-1 px-2 relative cursor-pointer"
            onClick={userModal}
          >
            <p className="text-sm mr-2">
              {userInfo?.userInfo?.firstName} {userInfo?.userInfo?.lastName[0]}.{" "}
            </p>
            {user?.photoURL ? (
              <img src={user?.photoURL} className="h-6 w-6 rounded-full" />
            ) : (
              <div className="h-8 w-8 bg-regal-blue flex justify-center items-center rounded-full">
                <p className="text-white text-xs">
                  {userInfo?.userInfo?.firstName[0]}
                  {userInfo?.userInfo?.lastName[0]}
                </p>
              </div>
            )}
          </Card>
        </div>
      </header>
      {openBox && (
        <div className="flex justify-end absolute right-0 mr-5 z-10">
          <div
            style={{
              border: "1px solid lightgray",
              borderRadius: "10px",
              background: "ghostwhite",
            }}
            className="p-5"
          >
            <div className="flex justify-center">
              {user?.photoURL ? (
                <img src={user?.photoURL} className="h-10 w-10 rounded-full" />
              ) : (
                <div className="h-10 w-10 bg-regal-blue flex justify-center items-center rounded-full">
                  <p className="text-white text-base">
                    {userInfo?.userInfo?.firstName[0]}
                    {userInfo?.userInfo?.lastName[0]}
                  </p>
                </div>
              )}
            </div>
            <p className="flex justify-center mt-1">
              Hi, {userInfo?.userInfo?.firstName}!
            </p>
            <span className="text-sm text-gray-500 underline">
              {userInfo?.userInfo?.email}
            </span>
            <div className="bg-dark-gray flex mt-2 rounded-lg justify-center">
              <BuildingIcon className="mt-1 mr-2" />
              <h6 className="text-sm"> {userInfo?.tenants[0]?.companyName}</h6>
            </div>
            <div className="flex mt-4 justify-center cursor-pointer">
              <SettingIcon className="mt-1 mr-2" />
              <h6 className="text-sm"> Settings</h6>
            </div>
            <div
              className="flex mt-2 justify-center cursor-pointer"
              onClick={() => logoutUser()}
            >
              <LogoutIcon className="mt-1 mr-2" />
              <h6 className="text-sm"> Sign Out</h6>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
